import { Performance } from '../../api-models'
import { Moment } from 'moment'
import {
  greenColorScheme,
  lightBlueColorScheme,
  lightOrangeColorScheme,
  mariTimeColorScheme,
  purpleColorScheme,
} from '../../theme'
import { FuelType } from '../../utils'
import { IMcTagOption } from './components/McTagGroup'

export enum StockState {
  STARTUP = 1,
  OPERATIONAL = 2,
  LOST = 3,
  RECOVERY = 4,
}

export interface FormBatchSounding {
  id: string
  fuelType: number
  displayName: string
  rob: number
  adjustment: number
  quantity: number
}

export interface FormBatch extends Performance.FuelOilStock.BunkeredBatch {
  id: string
  isFirstOfFuelType?: boolean
}

export interface InitialRobBatch
  extends Performance.FuelOilStock.BunkeredBatch {
  id: string
}

export interface MergedBatchRob extends Performance.FuelOilStock.BatchRob {
  displayName: string
  fuelType: number
  timestamp: string
}

export interface FullRobMarkEntry {
  batchQuantities: Map<number, MergedBatchRob[]>
  timestamp: string
  id: string
  notes: string
  readonly: boolean
}

export interface ExistingSounding {
  batchQuantities: FormBatchSounding[]
  timestamp: string
  id: string
  notes: string
  readonly: boolean
}

export enum UserEntryTypes {
  BunkeredBatch = 1,
  MixedBatch = 2,
  StartupRobMark = 3,
  Sounding = 4,
  Loss = 5,
  Debunkering = 6,
  RecoveryRobMark = 7,
  BatchSelection = 10,
  MEMainFuelLineSelection = 20,
  MESecondaryFuelLineSelection = 26,
  AEMainFuelLineSelection = 21,
  AESecondaryFuelLineSelection = 22,
  AEMDOFuelLineSelection = 23,
  BoilerMainFuelLineSelection = 24,
  BoilerMDOFuelLineSelection = 25,
}

export enum BatchIndicatorTypes {
  Normal = 1,
  Blending = 2,
  Error = 3,
}

export enum BatchIndicatorsLabels {
  Blending = 'Blending',
  Error = 'Error',
}

export enum EntryType {
  Batch = 10,
  MixedBatch = 11,
  RobMark = 20,
  Sounding = 21,
  Loss = 30,
  Debunkering = 31,
  Unknown_40 = 40,
  Unknown_100 = 100,
}

export const USER_ENTRY_TYPE_TO_ENTRY_TYPE = {
  [UserEntryTypes.BunkeredBatch]: EntryType.Batch,
  [UserEntryTypes.MixedBatch]: EntryType.MixedBatch,
  [UserEntryTypes.StartupRobMark]: EntryType.RobMark,
  [UserEntryTypes.Sounding]: EntryType.Sounding,
  [UserEntryTypes.Loss]: EntryType.Loss,
  [UserEntryTypes.Debunkering]: EntryType.Debunkering,
  [UserEntryTypes.RecoveryRobMark]: EntryType.RobMark,
  [UserEntryTypes.BatchSelection]: 'Batch selection',
  [UserEntryTypes.MEMainFuelLineSelection]: 'ME Main Fuel Line Selection',
  [UserEntryTypes.MESecondaryFuelLineSelection]:
    'ME Secondary Fuel Line Selection',
  [UserEntryTypes.AEMainFuelLineSelection]: 'AE Main Fuel Line Selection',
  [UserEntryTypes.AESecondaryFuelLineSelection]:
    'AE Secondary Fuel Line Selection',
  [UserEntryTypes.AEMDOFuelLineSelection]: 'AE MDO Fuel Line Selection',
  [UserEntryTypes.BoilerMainFuelLineSelection]:
    'Boiler Main Fuel Line Selection',
  [UserEntryTypes.BoilerMDOFuelLineSelection]: 'Boiler MDO Fuel Line Selection',
}

export const FUEL_OIL_USER_ENTRY_TYPE_NAMES = {
  [UserEntryTypes.BunkeredBatch]: 'Bunkered batch',
  [UserEntryTypes.MixedBatch]: 'Mixed batch',
  [UserEntryTypes.StartupRobMark]: 'Initial ROB',
  [UserEntryTypes.Sounding]: 'Sounding',
  [UserEntryTypes.Loss]: 'Loss',
  [UserEntryTypes.Debunkering]: 'Debunkering',
  [UserEntryTypes.RecoveryRobMark]: 'Recovery ROB',
  [UserEntryTypes.BatchSelection]: 'Batch selection',
  [UserEntryTypes.MEMainFuelLineSelection]: 'ME main fuel line selection',
  [UserEntryTypes.MESecondaryFuelLineSelection]:
    'ME secondary fuel line selection',
  [UserEntryTypes.AEMainFuelLineSelection]: 'AE main fuel line selection',
  [UserEntryTypes.AESecondaryFuelLineSelection]:
    'AE secondary fuel line selection',
  [UserEntryTypes.AEMDOFuelLineSelection]: 'AE MDO fuel line selection',
  [UserEntryTypes.BoilerMainFuelLineSelection]:
    'Boiler main fuel line selection',
  [UserEntryTypes.BoilerMDOFuelLineSelection]: 'Boiler MDO fuel line selection',
}

export const FUEL_STOCK_ENTRY_TYPE_NAMES = {
  [EntryType.Batch]: 'Bunkered batch',
  [EntryType.MixedBatch]: 'Mixed batch',
  [EntryType.RobMark]: 'ROB',
  [EntryType.Sounding]: 'Sounding',
  [EntryType.Loss]: 'Loss',
  [EntryType.Debunkering]: 'Debunkering',
  [EntryType.Unknown_40]: '40 (Unknown type)',
  [EntryType.Unknown_100]: '100 (Unknown type)',
}

export interface FuelTypeQuantity {
  fuelType: number
  quantity?: number
}

export interface StockOnBoard {
  timestamp?: Moment
  fuelTypeQuantities: FuelTypeQuantity[]
  batchQuantities: Performance.FuelOilStock.BatchQuantity[]
  burndown?: Performance.FuelOilStock.BurndownModel
}

export const fuelTypeOptions: Array<IMcTagOption> = [
  {
    label: FuelType[FuelType.HS],
    value: FuelType.HS,
    scheme: mariTimeColorScheme,
  },
  {
    label: FuelType[FuelType.VLS],
    value: FuelType.VLS,
    scheme: purpleColorScheme,
  },
  {
    label: FuelType[FuelType.ULS],
    value: FuelType.ULS,
    scheme: lightBlueColorScheme,
  },
  {
    label: FuelType[FuelType.MDO],
    value: FuelType.MDO,
    scheme: lightOrangeColorScheme,
  },
  {
    label: FuelType[FuelType.MM],
    value: FuelType.MM,
    scheme: greenColorScheme,
  },
]

export const fuelTypeSubOptions = {
  [FuelType.HS]: [
    {
      label: 'Residual',
      value: false,
      scheme: mariTimeColorScheme,
    },
  ],
  [FuelType.VLS]: [
    {
      label: 'Residual',
      value: false,
      scheme: purpleColorScheme,
    },
    { label: 'Distillate', value: true, scheme: purpleColorScheme },
  ],
  [FuelType.ULS]: [
    {
      label: 'Residual',
      value: false,
      scheme: lightBlueColorScheme,
    },
    { label: 'Distillate', value: true, scheme: lightBlueColorScheme },
  ],
  [FuelType.MDO]: [
    { label: 'Distillate', value: true, scheme: lightOrangeColorScheme },
  ],
}
