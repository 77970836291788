import { useRouteMatch } from 'react-router-dom'
import { FaroRoute } from '@grafana/faro-react'

import { vesselPages as pageData } from './VesselRoutes.const'
import { isFeatureEnabled, isShoreContext } from '../utils'
import { AppLayout } from '../layout'
import {
  ActivityLogPage,
  AuxiliaryEnginesPage,
  ClassComparisonPage,
  CounterPage,
  DataPointsPage,
  ElectricalProductionPage,
  EnginePerformanceTestPage,
  EnginePerformanceTestReportPage,
  FuelConsumptionPage,
  HDCOverviewPage,
  HDCReportPage,
  LubeOilPage,
  MalfunctioningEquipmentPage,
  MalfunctioningEquipmentReportPage,
  PropulsionPage,
  SensorsPage,
  StockManagementPage,
  TrimPage,
  VesselOverviewPage,
  WasteHeatRecoveryPage,
} from '../pages'
import HelpRoutes from './HelpRoutes'

export const pageSettings: Array<PageSettings> = [
  {
    isExact: true,
    component: VesselOverviewPage,
  },
  {
    isExact: true,
    component: ClassComparisonPage,
  },
  {
    isGroupName: true,
  },
  {
    component: FuelConsumptionPage,
  },
  {
    isDisabled: !isFeatureEnabled('stock-management'),
    component: StockManagementPage,
  },
  {
    isDisabled: false,
    component: LubeOilPage,
  },
  {
    isGroupName: true,
  },
  {
    component: HDCOverviewPage,
  },
  {
    hideInNav: true,
    component: HDCReportPage,
  },
  {
    component: EnginePerformanceTestPage,
    isExact: true,
  },
  {
    component: EnginePerformanceTestReportPage,
    hideInNav: true,
  },
  {
    component: MalfunctioningEquipmentPage,
    isExact: true,
  },
  {
    component: MalfunctioningEquipmentReportPage,
    hideInNav: true,
  },
  {
    component: CounterPage,
  },
  {
    component: SensorsPage,
  },
  {
    isDisabled: isShoreContext(),
    component: DataPointsPage,
  },
  {
    isGroupName: true,
  },
  {
    isDisabled: !isFeatureEnabled('propulsion'),
    component: PropulsionPage,
  },
  {
    isDisabled: !isFeatureEnabled('electrical-production'),
    component: ElectricalProductionPage,
  },
  {
    isDisabled: !isFeatureEnabled('auxiliary-engines'),
    component: AuxiliaryEnginesPage,
  },
  {
    isDisabled: !isFeatureEnabled('waste-heat-recovery'),
    equipment: 'whr',
    component: WasteHeatRecoveryPage,
  },
  {
    component: TrimPage,
  },
  {
    hideInNav: true,
    component: ActivityLogPage,
  },
  {
    hideInNav: true,
    component: HelpRoutes,
  },
]

export const pageProps: Array<PageProps> = pageSettings.map(
  (pageSettings, idx) => ({
    name: pageData[idx].name,
    pagePath: pageData[idx].pagePath,
    ...pageSettings,
  }),
)

const VesselRoutes = () => {
  const { path } = useRouteMatch()

  return (
    <AppLayout>
      {pageProps
        .filter((page) => page.pagePath !== '')
        .map((page) => (
          <FaroRoute
            key={page.pagePath}
            path={path + page.pagePath}
            exact={page.isExact}
          >
            <page.component />
          </FaroRoute>
        ))}
    </AppLayout>
  )
}

export default VesselRoutes
